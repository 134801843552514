@import url('https://fonts.googleapis.com/css?family=Roboto:100,300');

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  background: #111;
}

:root {
  --progress: 0% 100%;
}

.upload {
  position: relative;
  width: 400px;
  min-height: 445px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  background: #fff;
  -webkit-animation: fadeup 0.5s 0.5s ease both;
          animation: fadeup 0.5s 0.5s ease both;
  transform: translateY(20px);
  opacity: 0;
}
.upload .upload-files header {
  background: #9147FF;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}
.upload .upload-files header p {
  color: #fff;
  font-size: 40px;
  margin: 0;
  padding: 50px 0;
}
.upload .upload-files header p i {
  transform: translateY(20px);
  opacity: 0;
  font-size: 30px;
  -webkit-animation: fadeup 0.5s 1s ease both;
          animation: fadeup 0.5s 1s ease both;
}
.upload .upload-files header p .up {
  font-weight: bold;
  transform: translateX(-20px);
  display: inline-block;
  opacity: 0;
  -webkit-animation: faderight 0.5s 1.5s ease both;
          animation: faderight 0.5s 1.5s ease both;
}
.upload .upload-files header p .load {
  display: inline-block;
  font-weight: 100;
  margin-left: 0px;
  transform: translateX(-20px);
  opacity: 0;
  -webkit-animation: faderight 1s 1.5s ease both;
          animation: faderight 1s 1.5s ease both;
}
.upload .upload-files .body {
  text-align: center;
  padding: 50px 0;
  padding-bottom: 30px;
}
.upload .upload-files .body.hidden {
  display: none;
}
.upload .upload-files .body input {
  visibility: hidden;
}
.upload .upload-files .body i {
  font-size: 65px;
  color: lightgray;
}
.upload .upload-files .body p {
  font-size: 14px;
  padding-top: 15px;
  line-height: 1.4;
}
.upload .upload-files .body p b,
.upload .upload-files .body p a {
  color: #9147FF;
}
.upload .upload-files .body.active {
  border: dashed 2px #9147FF;
}
.upload .upload-files .body.active i {
  box-shadow: 0 0 0 -3px #fff, 0 0 0 lightgray, 0 0 0 -3px #fff, 0 0 0 lightgray;
  -webkit-animation: file 0.5s ease both;
          animation: file 0.5s ease both;
}
@-webkit-keyframes file {
  50% {
    box-shadow: -8px 8px 0 -3px #fff, -8px 8px 0 lightgray, -8px 8px 0 -3px #fff, -8px 8px 0 lightgray;
  }
  75%, 100% {
    box-shadow: -8px 8px 0 -3px #fff, -8px 8px 0 lightgray, -16px 16px 0 -3px #fff, -16px 16px 0 lightgray;
  }
}
@keyframes file {
  50% {
    box-shadow: -8px 8px 0 -3px #fff, -8px 8px 0 lightgray, -8px 8px 0 -3px #fff, -8px 8px 0 lightgray;
  }
  75%, 100% {
    box-shadow: -8px 8px 0 -3px #fff, -8px 8px 0 lightgray, -16px 16px 0 -3px #fff, -16px 16px 0 lightgray;
  }
}
.upload .upload-files .body.active .pointer-none {
  pointer-events: none;
}
.upload .upload-files footer {
  width: 100%;
  margin: 0 auto;
  height: 0;
}
.upload .upload-files footer .divider {
  margin: 0 auto;
  width: 0;
  border-top: solid 4px #975eed;
  text-align: center;
  overflow: hidden;
  transition: width 0.5s ease;
}
.upload .upload-files footer .divider span {
  display: inline-block;
  transform: translateY(-25px);
  font-size: 12px;
  padding-top: 8px;
}
.upload .upload-files footer.hasFiles {
  height: auto;
}
.upload .upload-files footer.hasFiles .divider {
  width: 100%;
}
.upload .upload-files footer.hasFiles .divider span {
  transform: translateY(0);
  transition: transform 0.5s 0.5s ease;
}
.upload .upload-files footer .list-files {
  width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  padding-left: 5px;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 210px;
}
.upload .upload-files footer .list-files::-webkit-scrollbar-track {
  background-color: rgba(211, 211, 211, 0.25);
}
.upload .upload-files footer .list-files::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(211, 211, 211, 0.25);
}
.upload .upload-files footer .list-files::-webkit-scrollbar-thumb {
  background-color: rgba(77, 182, 172, 0.5);
}
.upload .upload-files footer .list-files .file {
  width: 300px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  -webkit-animation: fade 0.35s ease both;
          animation: fade 0.35s ease both;
}
.upload .upload-files footer .list-files .file .name {
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
  text-align: left;
}
.upload .upload-files footer .list-files .file .progress {
  width: 175px;
  height: 5px;
  border: solid 1px lightgray;
  border-radius: 2px;
  background: linear-gradient(to left, rgba(77, 182, 172, 0.2), #9147FF) no-repeat;
  background-size: 100% 100%;
}
.upload .upload-files footer .list-files .file .progress.active {
  background-size: var(--progress);
}

@-webkit-keyframes progress {
  from {
    background-size: 0 100%;
  }
  to {
    background-size: 100% 100%;
  }
}
@keyframes progress {
  from {
    background-size: 0 100%;
  }
  to {
    background-size: 100% 100%;
  }
}
.upload .upload-files footer .list-files .file .done {
  cursor: inherit;
  width: 40px;
  height: 40px;
  background: #9147FF;
  border-radius: 50%;
  margin-left: -10px;
  transform: scale(0);
  position: relative;
}
.upload .upload-files footer .list-files .file .done:before {
  position: absolute;
  top: 0;
  left: -5px;
  font-size: 24px;
  opacity: 0;
}
.upload .upload-files footer .list-files .file .done:hover:before {
  transition: all 0.25s ease;
  top: -30px;
  opacity: 1;
}
.upload .upload-files footer .list-files .file .done.anim {
  -webkit-animation: done1 0.5s ease forwards;
          animation: done1 0.5s ease forwards;
}
.upload .upload-files footer .list-files .file .done.anim #path {
  -webkit-animation: done2 2.5s 0.5s ease forwards;
          animation: done2 2.5s 0.5s ease forwards;
}
.upload .upload-files footer .list-files .file .done #path {
  stroke-dashoffset: 7387.5942382813;
  stroke-dasharray: 7387.5942382813 7387.5942382813;
  stroke: #fff;
  fill: transparent;
  stroke-width: 50px;
}
@-webkit-keyframes done2 {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes done2 {
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes done1 {
  50% {
    transform: scale(0.5);
    opacity: 1;
  }
  80% {
    transform: scale(0.25);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
}
@keyframes done1 {
  50% {
    transform: scale(0.5);
    opacity: 1;
  }
  80% {
    transform: scale(0.25);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
}
.upload .upload-files footer .importar {
  outline: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
  border: solid 1px #9147FF;
  color: #9147FF;
  background: transparent;
  padding: 8px 15px;
  font-size: 12px;
  border-radius: 4px;
  font-family: Roboto;
  line-height: 1;
  cursor: pointer;
  transform: translateY(15px);
  opacity: 0;
  visibility: hidden;
  margin-left: calc(50% - 74px);
}
.upload .upload-files footer .importar.active {
  transition: transform 0.5s 1.5s ease, opacity 0.5s 1.5s ease, background;
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.upload .upload-files footer .importar:hover {
  background: #9147FF;
  color: #fff;
}
@-webkit-keyframes fadeup {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeup {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes faderight {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes faderight {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  to {
    opacity: 1;
  }
}
@keyframes fade {
  to {
    opacity: 1;
  }
}
@media (max-width: 400px) {
  .upload {
    width: 100%;
    height: 100%;
  }
}

.App {
  text-align: center;
  background-color: rgb(206, 213, 223);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Card {
  background-color: white;
  padding: 32px;
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

.loginColumn {
  margin: 0 auto;
  padding: 100px 20px 20px;
  max-width: 800px;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-6 {
  width: 50%;
}

.text-center {
  text-align: center;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11 {
  float: left;
}

.col-md-6 {
  width: 50%;
}

.ibox-content {
  clear: both;
}
.ibox-content {
  padding: 15px 20px 20px;
  border-width: 1px 0;
}
.ibox-title, .ibox-content {
  background-color: #fff;
  color: inherit;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
}

p {
  margin: 0 0 10px;
    margin-top: 0px;
}
.m-t {
  margin-top: 15px;
}

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
  border-top: 1px solid #eee;
}

.btn-twitch {
  background-color: #6441A5 !important;
  border-color: #6441A5 !important;
}
.m-b {
  margin-bottom: 15px;
}
.full-width {
  width: 100% !important;
}
.block, .clear {
  display: block;
}
.btn-primary {
  background-color: #1d89cf;
  border-color: #1d89cf;
  color: #fff;
}
.btn {
  border-radius: 3px;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  border-radius: 4px;
}

.btn-twitch:hover {
  background-color: #7A55BD !important;
}


small, .small {
  font-size: 75%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#successAnimationCircle {
  stroke-dasharray: 151px 151px;
  stroke: #1bb31b;
}

#successAnimationCheck {
  stroke-dasharray: 36px 36px;
  stroke: #1bb31b;
}

#successAnimationResult {
  fill: #1bb31b;
  opacity: 0;
}

#successAnimation.animated {
  -webkit-animation: 1s ease-out 0s 1 both scaleAnimation;
          animation: 1s ease-out 0s 1 both scaleAnimation;
}
#successAnimation.animated #successAnimationCircle {
  -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
          animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
}
#successAnimation.animated #successAnimationCheck {
  -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
          animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
}
#successAnimation.animated #successAnimationResult {
  -webkit-animation: 0.3s linear 0.9s both fadeIn;
          animation: 0.3s linear 0.9s both fadeIn;
}